import Logo from 'jsx:../svg/logo.svg';

export default function Header (props: {}) {
  return (
    <div className="h-12 bg-blue-50">
      <div className='p-2'>
        <Logo />
      </div>
    </div>
  )
}
