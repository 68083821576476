import Header from './components/Header'
import PasswordReset from './components/PasswordReset';
import RequestPasswordReset from './components/RequestPasswordReset'
import { ViewUser } from './interface'

export function App() {
  const view = ViewUser.resetPassword;

  const renderMainview = (v: ViewUser) => {
    switch (v) {
      case ViewUser.requestPasswordReset:
        return <RequestPasswordReset />
      case ViewUser.resetPassword:
        return <PasswordReset />
      default:
        return ''
    }
  }

  return (
    <div className="App">
      <Header />

      <div className='flex justify-center m-2 p-2'>
        { renderMainview(view) }
      </div>
    </div>
  )
}
