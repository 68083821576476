import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useState, useEffect } from "react"

const baseurl = 'http://localhost:8000'

enum Status {
  ok,
  error,
}

interface SubmissionStatus {
  status: Status,
  message?: string,
}

export default function PasswordReset () {
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [showPasswords, setShowPasswords] = useState(false)
  const [passwordsOk, setPasswordsOk] = useState('')
  const [searchParams, setSearchParams] = useState(new URLSearchParams(document.location.search))
  const [success, setSuccess] = useState<SubmissionStatus>(null)

  useEffect(
    () => {
      setPasswordsOk(checkPasswords(password1, password2))
    }, [password1, password2]
  )

  const submit = () => {
    if (passwordsOk == '') {
      const token = searchParams.get('token')
      fetch(
        baseurl + '/auth/reset-password',
        {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({token: token, password: password1})
        }
      ).then(
          (res) => {
            if (res.ok) setSuccess({status: Status.ok})
            else setSuccess({status: Status.error, message: `${res.statusText}`})
          }
        )
    } else {
      console.error('Passwords not ok')
    }
  }

  if (success && success.status == Status.error) {
    return (
      <h2>There was an error: {success.message ? success.message : 'unknown error'}</h2>
    )
  }

  const buttonStyle = (
    passwordsOk == ''
      ? 'bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700'
      : 'bg-teal-300 border-teal-300 cursor-not-allowed'
  ) + "flex-shrint-0 text-sm border-4 text-white  py-1 px-2 rounded"

  return (
    <div className="w-full max-w-sm">
      <div className="flex items-center border-b border-teal-500 py-2">
        <button onClick={() => setShowPasswords(!showPasswords)}>
          {
            showPasswords
            ? <EyeSlashIcon className='text-gray-700 w-6 h-6'/>
            : <EyeIcon className='text-gray-700 w-6 h-6'/>
          }
        </button>
        <input
          type={showPasswords ? "text" : "password"}
          value={password1}
          onChange={(e) => setPassword1(e.target.value)}
          placeholder="enter your new password"
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
        />
        <input
          type={showPasswords ? "text" : "password"}
          value={password2}
          onChange={(e) => setPassword2(e.target.value)}
          placeholder="re-enter your new password"
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
        />
        <button
          onClick={submit}
          className={buttonStyle}
        >
          Submit
        </button>
      </div>
      <div>{password1.length > 0 || password2.length > 0 ? passwordsOk : ''}</div>
    </div>
  )
}


function checkPasswords(p1: string, p2: string) : string {
  if (p1.length < 6) return 'too short'

  const has_lowercase = p1.search(/[a-z]/) >= 0
  const has_uppercase = p1.search(/[A-Z]/) >= 0
  const has_numeric = p1.search(/[0-9]/) >= 0
  const has_special = p1.search(/[^0-9a-zA-Z]/) >= 0
  const has_mixed_case = has_lowercase && has_uppercase

  if (!has_mixed_case) return 'password should have upper and lower case characters'
  if (!has_numeric) return 'password should container numbers'
  if (!has_special) return 'password should contain special characters'
  if (p2.length == 0) return '   '
  if (p1 != p2) return 'passwords differ'
  return ''
}
