'use client'
import { useState } from "react"

const baseurl = 'http://localhost:8000'

export default function RequestPasswordReset () {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState({status: false, message: ''})

  const trackChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value)
  }

  const validEmail = validateEmail(email)

  const submit = () => {
    if (validEmail) {
      fetch(baseurl + '/auth/forgot-password', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({email: email})
      })
      .then(
        (resp) => {
          if (resp.ok) {
            setSubmitted({status: true, message: ''})
          } else {
            setSubmitted({status: false, message: 'Error'})
          }
        }
      )
    }
  }

  const buttonStyle = (
    validEmail
      ? 'bg-blue-200'
      : 'bg-blue-50 cursor-not-allowed'
  ) + "flex-shrint-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white  py-1 px-2 rounded"

  if (submitted.status) {
    return (
      <div>
        <h3 className="text-md font-semibold">Your password request was submitted.</h3>
        <p>If your request corresponded to a registered email, you should shortly receive a message.</p>
      </div>
    )
  } else if (submitted.message.length > 0) {
    return (
      <div>Something went wrong: {submitted.message}</div>
    )
  }

  return (
    <div className="w-full max-w-sm">
      <h3>Please enter your email</h3>
      <div className="flex items-center border-b border-teal-500 py-2">
        <input
          type="email"
          value={email}
          onChange={trackChange}
          placeholder="enter your email"
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
        />
        <button
          onClick={submit}
          className={buttonStyle}
        >Reset password</button>
      </div>
      {
        !validEmail && email.length > 4
          ? <div>Invalid email</div>
          : ''
      }
    </div>
  )
}


function validateEmail(email: string) : boolean {
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return EMAIL_REGEX.test(email)
}
